import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  message,
  Modal,
  Spin,
  Table,
  Tooltip,
} from "antd";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import api from "../../api/rest";
import Title from "../../components/Title";
import { PlusOutlined } from "@ant-design/icons";
import { TypeSchema } from "../../models";
import CustomForm from "./Form";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import apiAssets from "../../api/assets";
import client from "../../lib/client";

const Crud = (props: TypeSchema) => {
  const { filters } = props;
  const [detailView, setDetailView] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const mutationAssets = useMutation("upload", apiAssets.insert);
  const createMutation = useMutation(
    `${props.collectionName}-create`,
    api(props.collectionName).insert
  );

  const updateMutation = useMutation(
    `${props.collectionName}-update`,
    api(props.collectionName).update
  );

  const deleteMutation = useMutation(
    `${props.collectionName}-delete`,
    api(props.collectionName).delete
  );

  const [current, setCurrent] = React.useState<any>(false);
  const [visible, setVisible] = React.useState(false);
  const dataQuery = useQuery(
    [props.collectionName, page, limit, filters || ""],
    () =>
      api(props.collectionName).find(
        { page, count: limit, ...(filters || {}) },
        props.parentId
      ),
    {
      select: props?.select,
    }
  );
  const { fields } = props;

  React.useEffect(() => {
    setPage(1);
  }, [filters]);

  const columns = fields
    ?.filter((f) => !f.hideInList)
    ?.map((f) => ({
      title: f.label,
      // dataIndex: f.name,
      key: f.name,
      ...(f.render
        ? {
            render: (row: any, ...rest: any) =>
              f.render(
                row,
                () => {
                  setCurrent(row);
                  setTimeout(() => {
                    setVisible(true);
                  }, 300);
                },
                rest
              ),
          }
        : {
            render: (row: any) => row[f.name] || "-",
          }),
    }));

  const handleSubmit = (values: any, _id: string, cb?: any) => {
    if (_id) {
      let tmp =
        props.parentId && props.id
          ? { _id, ...values, [props.id]: props.parentId }
          : { _id, ...values };
      return updateMutation
        .mutateAsync(tmp)
        .then(() => {
          dataQuery.refetch();
          setVisible(false);
          cb(true);
          message.success("Амжилттай засагдлаа !");
        })
        .catch((e) => {
          cb(false);
          console.log("CUSTOM ERROR: ", e);
          message.warning(
            e?.message || "Уучлаарай хүсэлт явуулах үед алдаа гарлаа !"
          );
        });
    } else {
      let tmp =
        props.parentId && props.id
          ? { ...values, [props.id]: props.parentId }
          : values;
      return createMutation
        .mutateAsync(tmp)
        .then(() => {
          dataQuery.refetch();
          setVisible(false);
          cb(true);
          message.success("Амжилттай нэмэгдлээ !");
        })
        .catch((e) => {
          cb(false);
          console.log("CUSTOM ERROR: ", e);
          message.warning(
            e?.message || "Уучлаарай хүсэлт явуулах үед алдаа гарлаа !"
          );
        });
    }
  };

  React.useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setCurrent(null);
      }, 500);
    }
  }, [visible]);

  const tmpColumns = [
    {
      title: "#",
      key: "index",
      width: 50,
      render: (_: any, __: any, i: any) => i + 1,
    },
    ...columns,
    {
      title: "Үйлдэл",
      key: "action",
      render: (_: any) => {
        return (
          <div className="flex space-x-4 items-center">
            {props?.actions?.map((action) => action.render(_))}

            <Tooltip title="Харах">
              <Button
                onClick={() => {
                  setCurrent(_);
                  setDetailView(true);
                }}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>

            {props.crud.update && (
              <Tooltip title="Засах">
                {props.redirect ? (
                  <Link to={`/${props.collectionName}/edit/${_.id}`}>
                    <Button>
                      <FiEdit />
                    </Button>
                  </Link>
                ) : (
                  <Button
                    onClick={() => {
                      setCurrent(_);
                      setVisible(true);
                    }}
                  >
                    <FiEdit />
                  </Button>
                )}
              </Tooltip>
            )}

            {props?.moreUrl && (
              <Tooltip title="Дэлгэрэнгүй">
                <Link
                  to={{
                    pathname: props?.moreUrl?.replace(":id", _.id),
                    state: {
                      org: _,
                    },
                  }}
                >
                  <Button>Дэлгэрэнгүй</Button>
                </Link>
              </Tooltip>
            )}
            {props.crud.delete && (
              <Tooltip title="Устгах">
                <Button
                  onClick={() => {
                    Modal.confirm({
                      content: "Та устгахдаа итгэлтэй байна уу ?",
                      icon: <ExclamationCircleOutlined />,
                      title: ``,
                      okButtonProps: {
                        danger: true,
                        loading: deleteMutation.isLoading,
                      },
                      okText: "Устгах",
                      cancelText: "Үгүй",
                      async onOk() {
                        deleteMutation.mutateAsync(_.id).then(() => {
                          dataQuery.refetch();
                        });
                      },
                      onCancel() {},
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  if (props.autoNumber !== undefined) {
    tmpColumns.splice(0, 1);
  }

  // if (!props.crud.update && !props.crud.delete) {
  //   tmpColumns.pop();
  // }

  return (
    <main>
      <Title
        title={props.title}
        suffix={
          props.crud.insert ? (
            <>
              {props.redirect ? (
                <Link to={`/${props.collectionName}/create`}>
                  <Button icon={<PlusOutlined />} size="large">
                    Нэмэх
                  </Button>
                </Link>
              ) : (
                <Button
                  onClick={() => {
                    setCurrent(null);
                    setVisible(true);
                  }}
                  icon={<PlusOutlined />}
                  size="large"
                >
                  Нэмэх
                </Button>
              )}
            </>
          ) : (
            <></>
          )
        }
      />

      {props?.renderFilters && <div>{props?.renderFilters()}</div>}

      <div className="bg-white rounded border border-red-500 overflow-hidden">
        <Table
          columns={tmpColumns}
          dataSource={
            props?.resFieldName
              ? (dataQuery?.data && dataQuery?.data[props.resFieldName]) || []
              : dataQuery?.data || []
          }
          loading={
            dataQuery.isLoading ||
            deleteMutation.isLoading ||
            updateMutation.isLoading
          }
          pagination={{
            total: dataQuery?.data?.count,
            pageSize: limit,
            current: page,
            onChange: (page, pageSize) => {
              setPage(page);
              setLimit(pageSize);
            },
          }}
        />
      </div>
      <Modal
        title={`${props.title} ${current ? "засах" : "нэмэх"}`}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Spin
          spinning={
            createMutation.isLoading ||
            updateMutation.isLoading ||
            mutationAssets.isLoading
          }
        >
          <CustomForm
            key={current ? "edit-me" : "create-me"}
            editData={current}
            fields={fields}
            submit={handleSubmit}
            mutationAssets={mutationAssets}
            close={() => {
              setVisible(false);
            }}
          />
        </Spin>
      </Modal>

      <Drawer
        title={`${props.title} - Мэдээлэл`}
        placement={"right"}
        closable={false}
        onClose={async () => {
          setDetailView(false);
          const trigger = props?.triggerActions?.preview?.(current);
          if (trigger) {
            await client.post(trigger?.url, trigger.data);
            dataQuery.refetch();
          }
        }}
        open={detailView}
        width={500}
      >
        <Descriptions bordered column={1}>
          {props?.fields
            ?.filter((field) => {
              return current && current[field.name];
            })
            .map((field, fieldIndex) => (
              <Descriptions.Item
                key={`${props.collectionName}-field-${fieldIndex}`}
                label={field.label}
              >
                {current && (
                  <>
                    {field.render ? field.render(current) : current[field.name]}
                  </>
                )}
              </Descriptions.Item>
            ))}
        </Descriptions>
      </Drawer>
    </main>
  );
};

export default Crud;
