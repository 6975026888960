// /organization/branch/list/{organization_id}

import { Tag } from "antd";
import { TypeSchema } from ".";

const ModelOrgsBranches: TypeSchema = {
  title: "Салбар/Ажилтан",
  collectionName: "orgs-branches",
  resFieldName: "organization_branches",
  autoNumber: false,
  id: "organization_id",
  crud: {
    find: "/organization/branch/list/:parentId",
    insert: "/organization/branch/add",
    update: "/organization/branch/:id/edit",
    delete: "/organization/branch/:id/remove",
  },
  select: (data) => {
    return {
      ...data,
      organization_branches: data?.organization_branches?.map((row: any) => ({
        ...row,
        location: {
          lat: row.lat,
          lng: row.lon,
        },
      })),
    };
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "select",
      label: "Төлөв",
      name: "status",
      options: [
        { title: "Идэвхтэй", value: "active" },
        { title: "Идэвхгүй", value: "deactive" },
      ],
      render: (row: any) => {
        return row?.status === "active" ? (
          <Tag color="blue">{row?.status}</Tag>
        ) : (
          <Tag>{row?.status}</Tag>
        );
      },
    },
    {
      required: true,
      type: "input",
      label: "Нэр",
      name: "name",
    },
    {
      required: false,
      type: "textarea",
      label: "Тайлбар",
      name: "description",
    },
    {
      required: false,
      type: "location",
      label: "Байршил",
      name: "location",
    },
  ],
};

export default ModelOrgsBranches;
