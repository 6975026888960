import { Input } from "antd";
import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import { IoMdPin } from "react-icons/io";

export interface Location {
  lat?: number;
  lng?: number;
}

export default function MapInput({
  value,
  onChange = (value: Location) => {},
  ...props
}: {
  value?: Location;
  onChange?: (value: Location) => void;
}) {
  const [internalValue, setInternalValue] = useState(value);
  const [inputValue, setInputValue] = useState(
    value &&
      !isNaN(value?.lat as number) &&
      !isNaN(value?.lng as number)
      ? `${value.lat}, ${value.lng}`
      : ""
  );
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setInputValue(val);
    if (val) {
      const [lat, lng] = val.split(",").map((str) => +str.trim());
      const newValue = {
        lat: isNaN(lat) ? undefined : lat,
        lng: isNaN(lng) ? undefined : lng,
      };
      setInternalValue(newValue);
      onChange(newValue);
    }
  };

  const handleDragEnd = (map: any) => {
    const newValue = {
      lat: map.center.lat(),
      lng: map.center.lng(),
    };
    onChange(newValue);
  };

  const handleDrag = (map: any) => {
    const newValue = {
      lat: map.center.lat(),
      lng: map.center.lng(),
    };
    setInternalValue(newValue);
    setInputValue(`${newValue.lat}, ${newValue.lng}`);
  };

  useEffect(() => {}, [value]);

  return (
    <div>
      <div className="relative overflow-hidden w-full h-80">
        <GoogleMapReact
          // bootstrapURLKeys={{
          //   key: process.env.REACT_APP_GOOGLE_API_KEY as string,
          // }}
          defaultZoom={10}
          defaultCenter={{
            lat: 47.917281620793744,
            lng: 106.9160866426443,
          }}
          onDragEnd={handleDragEnd}
          onDrag={handleDrag}
        >
          <div
            style={{
              marginTop: -24,
              marginLeft: -12
            }}
            // @ts-ignore
            lat={internalValue?.lat}

            // @ts-ignore
            lng={internalValue?.lng}
            key={"center"}
          >
            <IoMdPin size={24}/>
          </div>
        </GoogleMapReact>
      </div>
      <Input value={inputValue} onChange={handleValueChange}></Input>
    </div>
  );
}
