import { message, Spin } from "antd";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import api from "../../api/rest";
import Title from "../../components/Title";
import { TypeSchema } from "../../models";
import CustomForm from "./Form";
import { useHistory, useParams } from "react-router-dom";
import apiAssets from "../../api/assets";

const CU = (props: TypeSchema) => {
  const mutationAssets = useMutation("upload", apiAssets.insert);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const createMutation = useMutation(
    `${props.collectionName}-create`,
    api(props.collectionName).insert
  );

  const updateMutation = useMutation(
    `${props.collectionName}-update`,
    api(props.collectionName).update
  );

  const deleteMutation = useMutation(
    `${props.collectionName}-delete`,
    api(props.collectionName).delete
  );

  const { data: current, ...rest } = useQuery(
    [props.collectionName, id],
    () => (id ? api(props.collectionName).findOne(id) : Promise.reject()),
    {
      retry: false,
      refetchOnWindowFocus: false,
      select: props?.select,
    }
  );

  const { fields } = props;

  const handleSubmit = (values: any, _id: string, cb?: any) => {
    console.log("HANDLE SUBMIT: ", id, values, cb);
    if (_id) {
      return updateMutation
        .mutateAsync({ _id, ...values })
        .then((res) => {
          if (res?.error) {
            message.warning(res.error);
            cb(false);
          } else {
            cb(true);
            message.success("Амжилттай засагдлаа !");
            history.replace(`/${props.collectionName}`);
          }
        })
        .catch((e) => {
          cb(false);
          console.log("CUSTOM ERROR: ", e?.message);
          message.warning(
            typeof e?.message === "string"
              ? e?.message
              : "Уучлаарай хүсэлт явуулах үед алдаа гарлаа !"
          );
        });
    } else {
      return createMutation
        .mutateAsync(values)
        .then((res) => {
          if (res?.error) {
            message.warning(res.error);
            cb(false);
          } else {
            cb(true);
            message.success("Амжилттай нэмэгдлээ !");
            history.replace(`/${props.collectionName}`);
          }
        })
        .catch((e) => {
          cb(false);
          console.log("CUSTOM ERROR: ", e?.message);
          message.warning(
            typeof e?.message === "string"
              ? e?.message
              : "Уучлаарай хүсэлт явуулах үед алдаа гарлаа !"
          );
        });
    }
  };

  React.useEffect(() => {
    if (id) {
    }
  }, [id]);

  return (
    <main>
      <Title title={`${props.title} - ${current ? "Засах" : "Нэмэх"}`} back />

      <Spin
        spinning={
          createMutation.isLoading ||
          updateMutation.isLoading ||
          rest.isLoading ||
          mutationAssets.isLoading
        }
      >
        <div className="bg-white rounded border border-red-500 p-5 overflow-hidden">
          <CustomForm
            editData={current}
            mutationAssets={mutationAssets}
            fields={fields}
            submit={handleSubmit}
            close={() => {
              history.replace(`/${props.collectionName}`);
            }}
          />
        </div>
      </Spin>
    </main>
  );
};

export default CU;
