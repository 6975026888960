import { Divider, Tag } from "antd";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import Title from "../components/Title";
import ModelOrgsServices from "../models/orgs-services";
import ModelOrgsUsers from "../models/orgs-users";
import Crud from "./Crud";
import ModelOrgsBranches from "../models/orgs-branches";

interface IProps {}

const OrganizationsContainer = (props: IProps) => {
  const { state } = useLocation<any>();
  const { parentId } = useParams<any>();
  return (
    <section>
      <Title title={state?.org?.name} back />
      <div className="bg-white px-5 py-2 border shadow rounded">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <div>
              <img
                src={state?.org?.image}
                height={100}
                className="shadow border rounded"
              />
            </div>
            <div>
              <h3 className="text-xl font-bold">{state?.org?.name}</h3>
              <p>{state?.org?.description}</p>
            </div>
          </div>
          <div>
            {state?.org?.status === "active" ? (
              <Tag color="blue">{state?.org?.status}</Tag>
            ) : (
              <Tag>{state?.org?.status}</Tag>
            )}
          </div>
        </div>
        <Divider />
        {parentId && <Crud {...ModelOrgsBranches} parentId={parentId} />}
        <Divider />
        {parentId && <Crud {...ModelOrgsServices} parentId={parentId} />}
        <Divider />
        {parentId && <Crud {...ModelOrgsUsers} parentId={parentId} />}
      </div>
    </section>
  );
};

export default OrganizationsContainer;
